/** @format */

import { Button, Col, Collapse, Row, Spin, Grid } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import Link from "next/link";

import { useEffect, useState } from "react";
import responsiveColumn from "../../../../methods/responsiveColumn";
import type { Article } from "../../../../interfaces/articles";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

export default function BankruptcySection8() {
  const { t } = useTranslation("bankruptcy_section8");
  const { useBreakpoint } = Grid;
  const screenSize = useBreakpoint();
  const [faqs, setFaqs] = useState<any>();
  const router = useRouter();
  const { locale } = router;
  const fetchFAQS = async () => {
    try {
      const r = await axios({
        url: `https://wp.taxworkoutgroup.com/wp-json/wp/v2/faqs?_embed&per_page=100&${
          locale != "en" ? `lang=${locale}` : ""
        }`,
        method: "get",
      });

      setFaqs(
        r.data
          .filter((e: Article) => e.categories.includes(14))
          .filter((e: Article) => e.tags.includes(25))
          .sort((a: Article, b: Article) => a.acf.priority - b.acf.priority)
      );
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchFAQS();
  }, []);

  return (
    <Row style={{ padding: "50px 0px" }} gutter={[20, 20]}>
      <Col span={24}>
        <Row justify={"center"}>
          <Col
            {...responsiveColumn([12, 12, 12, 22, 22, 22])}
            style={{ textAlign: "center", lineHeight: 1.5 }}
          >
            <h2
              className="section_title"
              style={{
                // fontSize:screenSize.lg 48
                fontSize: screenSize.md ? 48 : 30,
              }}
            >
              {t("section8_title")}
            </h2>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "0px 0 40px 0" }}>
          <Col {...responsiveColumn([18, 19, 20, 22, 22, 22])}>
            <Spin spinning={!faqs ? true : false}>
              <Collapse
                expandIconPosition="right"
                style={{ border: "none" }}
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <MinusCircleOutlined
                      style={{
                        fontSize: 20,
                      }}
                    />
                  ) : (
                    <PlusCircleOutlined
                      style={{
                        fontSize: 20,
                      }}
                    />
                  )
                }
              >
                {faqs?.slice(0, 4)?.map((e: any) => {
                  return (
                    <Collapse.Panel
                      key={e.id}
                      header={e.title.rendered}
                      style={{
                        backgroundColor: "white",
                        border: "none",
                        padding: "10px 0",
                      }}
                    >
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e.content.rendered ?? "",
                        }}
                        style={{ overflow: "hidden", width: "100%" }}
                      ></p>
                    </Collapse.Panel>
                  );
                })}
              </Collapse>
            </Spin>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col>
            <Link href="/faqs">
              <Button
                size="large"
                type="primary"
                style={{ fontSize: 15, padding: "0 30px" }}
              >
                {t("section8_button")}
              </Button>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
