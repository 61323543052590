/** @format */

import { Col, Row, Grid } from "antd";
import responsiveColumn from "../../../../methods/responsiveColumn";
import HomeForm from "../../home/HomeForm";
import { useTranslation } from "next-i18next";

export default function BankruptcySection2() {
  const { useBreakpoint } = Grid;
  const screenSize = useBreakpoint();
  const { t } = useTranslation("bankruptcy_section2");
  return (
    <>
      <Row
        justify={"center"}
        style={{
          paddingTop: 40,
          paddingBottom: 40,
          marginTop: 30,
          lineHeight: 1.5,
        }}>
        <Col span={23} className={"wide-content"}>
          <Row gutter={[50, 50]} justify={"center"}>
            <Col
              {...responsiveColumn(
                [13, 11, 10, 23, 23, 23],
                [1, 1, 1, 2, 2, 2]
              )}
              style={{ lineHeight: 1.5 }}>
              <span className='orangeTitle'>{t("section2_orange")}</span>
              <br />
              <h2
                className='section_title'
                style={{
                  fontSize: screenSize.md ? 48 : 33,
                  marginBottom: 0,
                }}>
                {t("section2_title")}
              </h2>
              <div style={{ textAlign: "left", marginTop: 30 }}>
                <div style={{ fontSize: 20, lineHeight: 1.5 }}>
                  <b>{t("section2_p1")}</b>
                </div>
                <p style={{ marginTop: screenSize.md ? 10 : 20, fontSize: 16, lineHeight: "24px" }}>
                  {t("section2_p2")}
                </p>
                <p
                  className='blue_quote'
                  style={{ padding: 10, marginTop: 20, marginBottom: 20 }}>
                  {t("section2_quote")}
                </p>
                <div>
                  <p
                    style={{
                      fontSize: 20,
                      marginBottom: screenSize.md ? 10 : 30,
                    }}>
                    <b>{t("section2_p3")}</b>
                  </p>
                </div>
                <p style={{ marginTop: 10, fontSize: 16 }}>
                  {t("section2_p4")}
                </p>
                <p style={{ marginTop: 0, fontSize: 16 }}>{t("section2_p5")}</p>
                <p style={{ margin: "30px 0", fontSize: 16 }}>
                  {t("section2_p6")}
                </p>
                <div style={{ textAlign: "left" }}>
                  <p
                    style={{
                      fontSize: 20,
                      marginBottom: screenSize.md ? 10 : 30,
                    }}>
                    <b>{t("section2_p7")}</b>
                  </p>
                </div>
                <p style={{ marginTop: 10, marginBottom: 30, fontSize: 16 }}>
                  {t("section2_p8")}
                </p>
              </div>
            </Col>
            <Col
              {...responsiveColumn([9, 9, 10, 23, 23, 23], [2, 2, 2, 1, 1, 1])}>
              <HomeForm />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
