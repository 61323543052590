/** @format */

import { Breadcrumb, Col, Row } from "antd";
import parse from "html-react-parser";
import responsiveColumn from "../../methods/responsiveColumn";
import { useRouter } from "next/router";

export default function CustomBreadcrumb(props: {
	items: { label: string; href: string }[];
}) {
  const router = useRouter();

	const clickHandler = (url: string)=>{
    router.push(url);
	}
	
	return (
		<Row
			gutter={[20, 20]}
			justify={"center"}
			style={{
				paddingTop: 10,
				paddingBottom: 10,
				margin: 0,
				background: "rgba(37, 68, 134, 0.14)",
			}}>
			<Col {...responsiveColumn([16, 16, 16, 24, 24, 24])}>
				<Breadcrumb>
					{props.items.map((item) => {
						return (
							<Breadcrumb.Item key={item.label} onClick={()=>clickHandler(item.href)} className="hoverText">
								{parse(item.label)}
							</Breadcrumb.Item>
						);
					})}
				</Breadcrumb>
			</Col>
		</Row>
	);
}
