/** @format */

import { Button, Col, Row } from "antd";
import Link from "next/link";
import responsiveColumn from "../../../../methods/responsiveColumn";
import { useTranslation } from "next-i18next";

export default function BankruptcySection7() {
  const { t } = useTranslation("bankruptcy_section7");
  return (
    <>
      <Row
        style={{
          background: "#254486",
          padding: "80px 0 50px 0",
          color: "#fff",
          lineHeight: 1.5,
        }}
        justify={"center"}
        className='stext3'>
        <Col
          style={{ textAlign: "center" }}
          {...responsiveColumn([16, 17, 18, 23, 23, 23])}>
          <p style={{ fontSize: 20 }}>{t("section7_p1")}</p>
          <p style={{ fontSize: 16, margin: "30px 0", padding: "0 13px" }}>
            {t("section7_p2")}
          </p>
          <Link href='/about-us/our-unique-practice-model'>
            <Button
              size='large'
              style={{
                background: "#de9e36",
                border: "#de9e36",
                color: "#fff",
                padding: "0 100px",
                fontSize: 15,
              }}>
              {t("section7_button")}
            </Button>
          </Link>
        </Col>
      </Row>
    </>
  );
}
