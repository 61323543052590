/** @format */

import { Card, Col, Grid, Row } from "antd";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { convert } from "html-to-text";
import axios from "axios";
import Link from "next/link";
import { useEffect, useState } from "react";
import type { Articles } from "../../../../interfaces/articles";
import responsiveColumn from "../../../../methods/responsiveColumn";
import { useRouter } from "next/router";

export default function BankruptcySection6() {
  const [areas, setAreas] = useState<Articles>();

  const router = useRouter();
  const { locale } = router;
  const fetchArticles = async () => {
    try {
      const r = await axios({
        url: `https://wp.taxworkoutgroup.com/wp-json/wp/v2/practice_areas?_embed&per_page=100&categories=14&${
          locale != "en" ? `lang=${locale}` : ""
        }`,
        method: "get",
      });

      setAreas(
        r.data?.sort((a: any, b: any) => a.acf.priority - b.acf.priority)
      );
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchArticles();
  }, []);

  const { useBreakpoint } = Grid;
  const screenSize = useBreakpoint();

  return (
    <>
      <Row style={{ padding: "50px 0" }} justify='center'>
        <Col
          {...responsiveColumn([18, 19, 20, 22, 23, 23])}
          style={{ textAlign: "center", lineHeight: 1.5 }}
        >
          <div className='orangeTitle'>OUR SERVICES</div>
          <h2
            className='section_title'
            style={{ fontSize: screenSize.md ? 48 : 24 }}
          >
            Tax Bankruptcy Group Legal Services
          </h2>
          <Row justify={"center"} gutter={[30, 20]} style={{ marginTop: 30 }}>
            {areas?.map((service) => {
              return (
                <Col
                  {...responsiveColumn([8, 8, 8, 22, 22, 22])}
                  key={service.id}
                >
                  <Link
                    style={{
                      color: "#fff",
                      fontSize: 16,
                      fontWeight: screenSize.md ? 500 : 400,
                    }}
                    href={`/taxbankruptcy/${service.slug}`}
                  >
                    <Card
                      className={"hoverable-tile"}
                      hoverable
                      style={{
                        width: "100%",
                        height: 128,
                        textAlign: "center",
                        background: "#254486",
                        fontWeight: screenSize.md ? 600 : 500,
                        color: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "-9px 9px 15px rgb(0 0 0 / 50%)",
                      }}
                    >
                      {convert(service.title?.rendered)}
                    </Card>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </>
  );
}
