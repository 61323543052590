/** @format */

import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import Footer from "../../components/Footer";
import BankruptcySection1 from "../../components/practice_areas/tax_bankruptcy/BankruptcySection1";
import BankruptcySection2 from "../../components/practice_areas/tax_bankruptcy/BankruptcySection2";
import BankruptcySection3 from "../../components/practice_areas/tax_bankruptcy/BankruptcySection3";
import BankruptcySection4 from "../../components/practice_areas/tax_bankruptcy/BankruptcySection4";
import BankruptcySection5 from "../../components/practice_areas/tax_bankruptcy/BankruptcySection5";
import BankruptcySection6 from "../../components/practice_areas/tax_bankruptcy/BankruptcySection6";
import BankruptcySection7 from "../../components/practice_areas/tax_bankruptcy/BankruptcySection7";
import BankruptcySection8 from "../../components/practice_areas/tax_bankruptcy/BankruptcySection8";
import { getStaticProps } from "../../../methods/i18n";
import { useTranslation } from "next-i18next";
import SEO from "../../components/seo";
import { HeadScriptTag } from "../../components/HeadScriptTag";

export { getStaticProps };

export default function TaxBankruptcyGroup() {
  const { t } = useTranslation("bankruptcy, meta_tags");

  const title = t("meta_tags:tax_bankruptcy");
  const description = t("meta_tags:tax_bankruptcy_desc")
  const keywords = "Tax,Tax Bankruptcy ,Tax Controversy";
  // const ogImage = "https://www.example.com/static/images/og-image.jpg";
  const ogType = "website";
  const canonicalURL = "https://taxworkoutgroup.com/taxbankruptcy";
  return (
    <>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        // ogImage={ogImage}
        ogType={ogType}
        canonicalURL={canonicalURL}
      />
      <HeadScriptTag />
      <CustomBreadcrumb
        items={[
          {
            label: t("bankruptcy:home"),
            href: "/",
          },
          {
            label: t("bankruptcy:practice"),
            href: "/practice-areas",
          },
          {
            label: t("bankruptcy:tax_bankruptcy"),
            href: "/taxbankruptcy",
          },
        ]}
      />
      <BankruptcySection1 />
      <BankruptcySection2 />
      <BankruptcySection3 />
      <BankruptcySection4 />
      <BankruptcySection5 />
      <BankruptcySection6 />
      <BankruptcySection7 />
      <BankruptcySection8 />
      <Footer />
    </>
  );
}
