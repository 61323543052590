/**
 * eslint-disable
 *
 * @format
 */

import { Col, Grid, Row } from "antd";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import responsiveColumn from "../../../../methods/responsiveColumn";
import useWindowSize from "../../../../methods/useWindowResize";

export default function BankruptcySection3() {
	const textContainer = useRef(null);
	const textHeight = (textContainer?.current as any)?.offsetHeight;
	const [imageHeight, setImageHeight] = useState(textHeight);
	const { useBreakpoint } = Grid;
	const screenSize = useBreakpoint();
	const size = useWindowSize();
	const { t } = useTranslation("bankruptcy_section3");

	const [overlay, setOverlay] = useState(true);
	const video = useRef(null);

	useEffect(() => {
		if (!overlay)
			try {
				(video.current as any).play();
			} catch (e) {
				console.log(e);
			}
	}, [overlay]);

	useEffect(() => {
		const textHeight = (textContainer?.current as any)?.offsetHeight;
		setImageHeight(textHeight);
	}, [size.width]);

	return (
		<Row
			justify={"center"}
			gutter={[30, 30]}
			style={{
				background: "#254486",
				paddingTop: 60,
				paddingBottom: 60,
				lineHeight: 1.5,
				color: "#fff",
			}}>
			<Col {...responsiveColumn([19, 19, 20, 23, 23, 23])}>
				<Row justify={"center"} gutter={[50, 50]}>
					<Col
						{...responsiveColumn([12, 12, 12, 23, 23, 23], [1, 1, 1, 2, 2, 2])}>
						<div style={{ fontSize: 20 }}>
							<b>{t("section3_p1")}</b>
						</div>
						<p style={{ margin: "30px 3px 30px 0px", fontSize: 16 }}>
							{t("section3_p2")}
						</p>
						<p style={{ margin: "30px 3px 30px 0px", fontSize: 16 }}>
							{t("section3_p3")}
						</p>
					</Col>
					<Col
						{...responsiveColumn([12, 12, 12, 23, 23, 23], [2, 2, 2, 1, 1, 1])}>
						<div
							style={{
								position: "relative",
								width: "100%",
								cursor: "pointer",
							}}>
							<video
								src='https://wp.taxworkoutgroup.com/wp-content/uploads/2023/01/tax_masters_video.mp4'
								ref={video}
								controls={!overlay}
								width='100%'
							/>
							<div
								style={{
									background: "rgba(0,0,0,0.5)",
									color: "#fff",
									display: overlay ? "block" : "none",
									width: "100%",
									top: 0,
									height: "100%",
									margin: "auto",
									position: "absolute",
									backgroundSize: "cover",
									backgroundPosition: "center center",
								}}
								onClick={() => {
									setOverlay(false);
								}}>
								<div className='fake_video'>
									<Image
										alt='play btn'
										width={140}
										height={144}
										className='video_btn'
										src='/play_button.png'
									/>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
