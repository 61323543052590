/** @format */

import { Button, Col, Row, Grid } from "antd";
import Link from "next/link";
import responsiveColumn from "../../../../methods/responsiveColumn";
import { useTranslation } from "next-i18next";

export default function BankruptcySection1() {
  const { useBreakpoint } = Grid;
  const screenSize = useBreakpoint();
  const { t } = useTranslation("bankruptcy_section1");
  return (
    <>
      <Row align="middle">
        <Col
          span={24}
          style={{
            backgroundImage: 'url("/banckruptcy_cover_a.webp")',
            color: "#fff",
            height: "700px",
            width: "100%",
            lineHeight: 1.5,
            backgroundPosition: "bottom center",
            backgroundSize: "cover",
          }}
        >
          <Row
            justify={"center"}
            align="middle"
            style={{ height: "100%", background: "rgba(0,0,0,0.6)" }}
          >
            <Col
              {...responsiveColumn([16, 17, 18, 20, 23, 23])}
              style={{ textAlign: "center" }}
            >
              <h2
                style={{
                  fontSize: screenSize.lg ? 20 : 16,
                  marginTop: screenSize.lg ? "20px" : "10px",
                }}
              >
                {t("section1_top_subtitle")}
              </h2>
              <h1
                className="section_title"
                style={{
                  marginTop: screenSize.lg ? 20 : 10,
                  paddingTop: screenSize.md ? 10 : 10,
                  lineHeight: 1,
                  fontSize: screenSize.md ? 52 : 34,
                }}
              >
                {t("section1_title")}
              </h1>
              <h3
                className="subtitle"
                style={{
                  margin: screenSize.lg ? "30px 40px" : "20px 30px",
                  lineHeight: 1.5,
                  fontSize: screenSize.md ? 20 : 16,
                }}
              >
                {t("section1_bottom_subtitle")}
              </h3>
              <Link href="/new-clients-intake-overview">
                <Button
                  type="primary"
                  size="large"
                  style={{
                    marginTop: screenSize.lg ? 20 : 0,
                    fontSize: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                >
                  {t("section1_button")}
                </Button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
