import { Button, Col, Row, Grid } from "antd";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import responsiveColumn from "../../../../methods/responsiveColumn";

export default function BankruptcySection5() {
  const {useBreakpoint} = Grid;
  const screenSize = useBreakpoint();
  const { t } = useTranslation("bankruptcy_section5");
  return (
    <>
      <Row
        style={{
          background: "#254486",
          padding: "80px 0 50px 0",
          color: "#fff",
        }}
        justify={"center"}
        className="stext3"
      >
        <Col
          style={{ textAlign: "center" }}
          {...responsiveColumn([16, 17, 18, 22, 22, 22])}
        >
          <p style={{ fontSize: 20, padding: "0 10px ", lineHeight: 1.5 }}>
            {t("section5_p1")}
          </p>
          <p
            style={{
              fontSize: screenSize.xs? 15: 16,
              margin: screenSize.xs? "30px 0" :"30px 4px",
              padding: "0 10px ",
              lineHeight: 1.5,
            }}
          >
            {t("section5_p2")}
          </p>
          <Link href="/new-clients-intake-overview">
            <Button
              size="large"
              style={{
                background: "#de9e36",
                border: "#de9e36",
                color: "#fff",
                padding: "0 60px",
                fontSize: 15,
                marginTop: 5,
              }}
            >
              {t("section5_button")}
            </Button>
          </Link>
        </Col>
      </Row>
    </>
  );
}
