/** @format */

import { Col, Grid, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import responsiveColumn from "../../../../methods/responsiveColumn";
import useWindowSize from "../../../../methods/useWindowResize";
import { useTranslation } from "next-i18next";
import Image from "next/image";

export default function BankruptcySection4() {
	const textContainer = useRef(null);
	const textHeight = (textContainer?.current as any)?.offsetHeight;
	const [imageHeight, setImageHeight] = useState(textHeight);

	const textContainer1 = useRef(null);
	const textHeight1 = (textContainer1?.current as any)?.offsetHeight;
	const [imageHeight1, setImageHeight1] = useState(textHeight1);

	const { useBreakpoint } = Grid;
	const screenSize = useBreakpoint();
	const size = useWindowSize();
	const { t } = useTranslation("bankruptcy_section4");

	useEffect(() => {
		const textHeight = (textContainer?.current as any)?.offsetHeight;
		setImageHeight(textHeight);
		const textHeight1 = (textContainer1?.current as any)?.offsetHeight;
		setImageHeight1(textHeight1);
	}, [size.width]);

	return (
		<Row
			style={{ padding: "50px 0", lineHeight: 1.5 }}
			gutter={[40, 40]}
			justify='center'>
			<Col {...responsiveColumn([17, 23, 24, 24, 24, 24])}>
				<Row justify={"center"} gutter={[40, 40]}>
					<Col
						{...responsiveColumn([9, 10, 10, 23, 23, 23])}
						style={{ textAlign: screenSize.md ? "right" : "left" }}>
						<div style={{ display: "flex", justifyContent: "center" }}>
							<Image
								src='/tax_bankruptcy_section_1.png'
								width={411}
								height={screenSize.md ? (imageHeight ?? 0) - 20 : 616}
								alt='layer'
								className='stext5'
							/>
						</div>
					</Col>
					<Col
						{...responsiveColumn([13, 13, 13, 22, 22, 22])}
						ref={textContainer}>
						<Row gutter={[20, 20]}>
							<Col span={24} style={{ fontSize: 20 }}>
								<b>{t("section4_p1")} </b>
							</Col>
							<Col
								span={24}
								style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }}>
								<p>{t("section4_p2")}</p>
							</Col>
							<Col span={24}>
								<p style={{ fontSize: 16, paddingBottom: 15 }}>
									{t("section4_p3")} <b>{t("section4_p3_b1")} </b>
									{t("section4_p8")}
								</p>
							</Col>
							<Col span={24}>
								<p style={{ fontSize: 16 }}>{t("section4_p4")}</p>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>

			<Col {...responsiveColumn([17, 22, 23, 24, 24, 24])}>
				<Row justify={"center"} gutter={[40, 40]}>
					<Col
						{...responsiveColumn([13, 13, 13, 22, 22, 22], [1, 1, 1, 2, 2, 2])}
						ref={textContainer1}>
						<Row gutter={[20, 20]}>
							<Col span={24} style={{ fontSize: 20, paddingBottom: 20 }}>
								<b>{t("section4_p5")}</b>
							</Col>
							<Col span={24} style={{ fontSize: 16 }}>
								<p style={{ marginRight: 4 }}>{t("section4_p6")}</p>
							</Col>
							<Col span={24} style={{ fontSize: 16, marginRight: 7 }}>
								<p style={{ marginRight: 7 }}>
									{t("section4_p7_1")} <b>{t("section4_p7_b1")} </b>
									{t("section4_p7_2")} <b>{t("section4_p7_b2")} </b>
									{t("section4_p7_3")}
								</p>
							</Col>
						</Row>
					</Col>
					<Col
						{...responsiveColumn([9, 10, 10, 22, 22, 22], [2, 2, 2, 1, 1, 1])}>
						<div style={{ display: "flex", justifyContent: "center" }}>
							<Image
								src='/tax_bankruptcy_section_2.png'
								// //preview={false}
								width={463}
								height={screenSize.md ? (imageHeight1?? 0) - 60 : 336}
								alt='woman'
								style={{ textAlign: screenSize.md ? "right" : "left" }}
								className='stext5'
							/>
						</div>
					</Col>
				</Row>
				<Row justify={"center"}>
					<Col
						{...responsiveColumn([21, 21, 21, 22, 22, 22])}
						style={{ maxWidth: 920 }}>
						<p className='blue_quote' style={{ padding: "0 9px" }}>
							{t("section4_quote")}
						</p>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
